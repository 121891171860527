<mat-sidenav-container> 
    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
           <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>

        <div [perfectScrollbar]="" class="vertical-menu-wrapper">
            <app-vertical-menu [menuParentId]="0"></app-vertical-menu>    
        </div>       

        <div class="divider"></div>
        <app-social-icons [iconSize]="'lg'" [iconColor]="'primary'" fxLayout="row" fxLayoutAlign="center center"></app-social-icons>
        
    </mat-sidenav>

    <mat-sidenav-content class="page-wrapper" fxLayout="column" fxLayoutAlign="start stretch">

        <header [ngClass]="'toolbar-'+settings.toolbar" [class.has-bg-image]="settings.headerBgImage"  [class.has-bg-video]="settings.headerBgVideo" [class.main-toolbar-fixed]="settings.mainToolbarFixed">

            <app-toolbar1 *ngIf="settings.toolbar == 1" (onMenuIconClick)="sidenav.toggle()"></app-toolbar1> 
        
        </header>
          
        <main [class.content-offset-to-top]="settings.contentOffsetToTop">
            <router-outlet></router-outlet>              
        </main>

        <div class="sticky-footer-helper" fxFlex="grow"></div>

        <app-footer></app-footer>
       
        <!-- <div fxLayout="row" fxLayoutAlign="center center" class="options-icon" (click)="login.toggle()">
            <mat-icon>settings</mat-icon>
        </div> -->
        
        <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top" (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div>

    </mat-sidenav-content> 
    
    <mat-sidenav #options position="end" class="options" autoFocus="false">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="op-header mat-elevation-z1">
            <h2>Settings</h2>
            <button mat-icon-button (click)="options.toggle()" color="accent">
                <mat-icon>close</mat-icon>
            </button>
        </div> 
        <div [perfectScrollbar]="">
            <div fxLayout="column" class="control">
                <h4>Layout</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Sticky Menu Toolbar</span>
                    <mat-slide-toggle [checked]="settings.stickyMenuToolbar" (change)="settings.stickyMenuToolbar = !settings.stickyMenuToolbar" labelPosition="before"></mat-slide-toggle>
                </div>                 
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>RTL</span>
                    <mat-slide-toggle [checked]="settings.rtl" (change)="settings.rtl = !settings.rtl" labelPosition="before"></mat-slide-toggle>
                </div> 
            </div>
            
            <div fxLayout="column" class="control">
                <h4>Choose header type</h4>
                <mat-radio-group [(ngModel)]="headerTypeOption" (change)="chooseHeaderType()">
                    <mat-radio-button *ngFor="let headerType of headerTypes" [value]="headerType">{{headerType}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose theme skin</h4> 
                <div fxLayout="row" fxLayoutAlign="space-between center"> 
                    <div class="skin-primary red" (click)="changeTheme('red')"></div>
                    <div class="skin-primary blue" (click)="changeTheme('blue')"></div>  
                    <div class="skin-primary green" (click)="changeTheme('green')"></div>  
                    <div class="skin-primary pink" (click)="changeTheme('pink')"></div> 
                    <div class="skin-primary purple" (click)="changeTheme('purple')"></div>
                    <div class="skin-primary grey" (click)="changeTheme('grey')"></div>
                    <div class="skin-primary orange-dark" (click)="changeTheme('orange-dark')"><div class="skin-secondary"></div></div>
                </div>
            </div>

            <!-- <div fxLayout="column" class="control">
                <h4>Choose search panel variant</h4>
                <mat-radio-group [(ngModel)]="searchPanelVariantOption" (change)="chooseSearchPanelVariant()">
                    <mat-radio-button *ngFor="let searchPanelVariant of searchPanelVariants" [value]="searchPanelVariant">variant-{{searchPanelVariant}}</mat-radio-button>
                </mat-radio-group>
                <mat-slide-toggle [checked]="settings.searchOnBtnClick" (change)="settings.searchOnBtnClick = !settings.searchOnBtnClick" labelPosition="after">Search On Button Click</mat-slide-toggle>
            </div>  -->

        </div>
    </mat-sidenav>
    <mat-sidenav #login position="end" class="login" autoFocus="false">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="op-header mat-elevation-z1">
            <button mat-icon-button (click)="login.toggle()" color="accent">
                <mat-icon>close</mat-icon>
            </button>
        </div> 
        <div [perfectScrollbar]="" fxLayout="column">
            <div fxFlex="100" fxFlex.gt-sm="100" class="p-0" [style.background-image]="bgImage" class="bg-cover"> 
            </div> 
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="100" class="py-3" ngClass.gt-sm="px-5" ngClass.sm="px-3" ngClass.xs="px-3">  
                <div fxLayout="column" fxLayoutAlign="center center" class="text-center">  
                    <h1 class="secondary-font">Sign In</h1>  
                    <a mat-button routerLink="/register" color="accent" class="w-100">Don't have an account? Sign up now!</a>    
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
                    <mat-form-field appearance="outline" *ngIf="!showOtpComponent" class="w-100 mt-2">
                        <mat-icon matPrefix class="mr-1 text-muted">person</mat-icon>
                        <mat-label>Mobile Number</mat-label>
                        <input matInput placeholder="Mobile Number" formControlName="phone" required>
                        <mat-error *ngIf="loginForm.controls.phone.errors?.required">Mobile Number is required</mat-error>
                        <mat-error *ngIf="loginForm.controls.phone.hasError('invalidPhone')">Invalid Phone number</mat-error>
                    </mat-form-field> 
                    <ng-otp-input class="w-100 mt-2"  (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config"></ng-otp-input>
                    <p class="text-center mt-2" *ngIf="showOtpComponent"><a mat-raised-button (click)="onLoginFormSubmit()">Resend code</a></p>
                    <!-- <mat-slide-toggle formControlName="rememberMe" class="my-2">Keep me signed in</mat-slide-toggle> -->
                    <div class="text-center mt-2" *ngIf="!showOtpComponent"> 
                        <button mat-raised-button color="accent" class="uppercase" type="submit">
                            Get OTP
                        </button>
                    </div>
                    <div class="text-center mt-2" *ngIf="showOtpComponent"> 
                        <h3 *ngIf="mobileNumber !==''" class="text-muted ws-nowrap fw-500 p-2">OTP sent to {{mobileNumber}}</h3>
                        <button mat-raised-button color="accent" class="uppercase" (click)="verifyOTP()" type="button">
                           Verify
                        </button>
                    </div>     
                    <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3">
                        <div class="divider w-100"></div>
                        <h3 class="text-muted ws-nowrap fw-500 p-2">or Sign in with one click</h3>                       
                        <div class="divider w-100"></div>
                    </div>   -->
                    <!-- <div class="text-center py-3">
                        <button mat-mini-fab color="primary" matTooltip="Facebook" type="button">
                            <svg class="mat-icon" viewBox="0 0 24 24">
                                <path d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z" />
                            </svg>
                        </button>                        
                        <button mat-mini-fab color="primary" matTooltip="Twitter" type="button" class="mx-3">
                            <svg class="mat-icon" viewBox="0 0 24 24">
                                <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                            </svg>
                        </button>
                        <button mat-mini-fab color="primary" matTooltip="Google" type="button">
                            <svg class="mat-icon" viewBox="0 0 24 24">
                                <path d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />
                            </svg>
                        </button> 
                    </div>      -->
                </form>
                <!-- <div fxLayout="row" fxLayoutAlign="end center">
                    <button mat-button>
                        <mat-icon class="text-muted">vpn_key</mat-icon>
                        <span class="mx-1">Reset Password</span>
                    </button> 
                </div>   -->
            </div>

        </div>
    </mat-sidenav>
    
</mat-sidenav-container> 