import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'Home', '/', null, null, false, 0), 
    new Menu (2, 'NAV.ABOUT_US', '/about', null, null, false, 0),
    new Menu (3, 'Home Chef', '/', null, null, false, 0),
    new Menu (70, 'NAV.CONTACT', '/contact', null, null, false, 0)   
]

export const verticalMenuItems = [ 
    new Menu (1, 'Home', '/', null, null, false, 0), 
    new Menu (2, 'NAV.ABOUT_US', '/about', null, null, false, 0),
    new Menu (3, 'Home Chef', '/', null, null, false, 0),
    new Menu (70, 'NAV.CONTACT', '/contact', null, null, false, 0)
]