<span fxLayoutAlign="center center" class="item" fxShow="true" fxShow.gt-md>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <span class="px-1"><button mat-button  class="lang-btn" (click)="openPincode()" fxShow="true" fxShow.gt-md>
    {{ appService.Data.community?.name ? (appService.Data.community.name) : 'Select Community'}}  
  </button></span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">person</mat-icon>
  <span class="px-1"><a  routerLink="/register">Become Chef</a></span>
</span>
