import { Component, EventEmitter, Input, OnInit, SimpleChange } from '@angular/core';
import { MenuItem } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { CartOverviewComponent } from '../cart-overview/cart-overview.component';
import { Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Output() onChange = new EventEmitter<any>();
  @Input() menuItem!: any;
  @Input() deliveryDate!: any;
  @Input() lazyLoad: boolean = false;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: any; 
  public column:number = 4;
  constructor(public appService:AppService,public router:Router) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue); 
    } 
    //alert('sferfef');  
  }

  public getColumnCount(value:number){
    if(value == 25){
      this.column = 4;
    }
    else if(value == 33.3){
      this.column = 3;
    }
    else if(value == 50){
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }

  public addToCart(){
    var selectedDate = localStorage.getItem('selectedDate');
    var previousDate = localStorage.getItem('previousDate');
    let previousCategory = localStorage.getItem('selectedCategoryItems');
    if(moment(selectedDate).format('MM/DD/YYYY') == moment(previousDate).format('MM/DD/YYYY') && (previousCategory == this.menuItem.food_type_id || previousCategory == null) ){
      localStorage.setItem('selectedCategoryItems',this.menuItem.food_type_id);
      this.menuItem.delivery_date = this.deliveryDate;
      this.appService.addToCart(this.menuItem, null); 
      this.onChange.emit(this.menuItem);
    }else{
      const title = 'Do you want replace cart?';
      const message = 'Already you have item in your cart with another Mealtime. Do you want to replace item?';
			let dialogRef = this.appService.openConfirmDialog(title, message!);
			dialogRef.afterClosed().subscribe(dialogResult => {
				if(dialogResult){ 
          localStorage.setItem('previousDate',this.deliveryDate);
          this.appService.Data.cartList.length = 0;
          this.appService.Data.totalPrice = 0;
          this.appService.Data.totalCartCount = 0;
          this.menuItem.delivery_date = this.deliveryDate;
          this.appService.addToCart(this.menuItem, null); 
          this.onChange.emit(this.menuItem);
				}
			})
    }
    
  }

  public onCart(){
    console.log(this.menuItem.id);
    if(this.appService.Data.cartList.find(item=>(item.id == this.menuItem.id && moment(item.delivery_date).format('MM/DD/YYYY') == moment(this.deliveryDate).format('MM/DD/YYYY'))) ||  this.appService.categoryTimeCheck(this.menuItem.chef_id,this.menuItem.food_type_id,this.deliveryDate)){
      return true;
    }
    return false;
  }

  public addToFavorites(){
    this.appService.addToFavorites(this.menuItem);
  }

  public onFavorites(){
    if(this.appService.Data.favorites.find(item=>item.id == this.menuItem.id)){
      return true;
    }
    return false;
  }

  viewProduct(id : number){ 
      const param: NavigationExtras = {
        queryParams: {
          date: this.deliveryDate
        }
      };
      this.router.navigate(['/menu', id], param);
  }

}
