import { Component, OnInit, ViewChild, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { isPlatformBrowser } from '@angular/common';
import { AppService } from '../app.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOtpInputComponent } from 'ng-otp-input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { phoneValidator } from 'src/app/theme/utils/app-validators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav') sidenav:any;   
  public headerTypes = ['default', 'image', 'carousel', 'video'];
  public headerTypeOption:string = ''; 
  public headerFixed: boolean = false;
  public showBackToTop: boolean = false;
 
  public loginForm!: FormGroup;
  public hide = true;
  public bgImage:any;
  public showOtpComponent = false;
  @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput!:NgOtpInputComponent;
  public config  = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    inputClass:'otp-inputf',
    placeholder: '-'
  };
  public otp: any;
  id: any;
  mobileNumber: string='';

  public settings: Settings;
  constructor(public appSettings:AppSettings,
              public fb: FormBuilder,
              private sanitizer:DomSanitizer,
              private snackBar: MatSnackBar, 
              public router:Router, 
              @Inject(PLATFORM_ID) private platformId: Object, 
              public appService:AppService) {
    this.settings = this.appSettings.settings;  
  } 

  ngOnInit() {    
    this.headerTypeOption = this.settings.header;
    this.getCategories();
    this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(assets/images/others/login.jpg)');
    this.loginForm = this.fb.group({
      phone: [null, Validators.compose([Validators.required ,phoneValidator])],
      otp: [null],
      rememberMe: false
    }); 
    //this.getProducts(); 
  }
  
  public changeTheme(theme:string){
    this.settings.theme = theme;       
  }


  public chooseHeaderType(){
    this.settings.header = this.headerTypeOption;    
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0,0);
    } 
    this.router.navigate(['/']);
  }
 

  @HostListener('window:scroll') onWindowScroll() {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    (scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false; 

    if(this.settings.stickyMenuToolbar){      
      let top_toolbar = document.getElementById('top-toolbar');
      if(top_toolbar){ 
        if(scrollTop >= top_toolbar.clientHeight) {
          this.settings.mainToolbarFixed = true;
        }
        else{
          if(!document.documentElement.classList.contains('cdk-global-scrollblock')){
            this.settings.mainToolbarFixed = false;
          }  
        } 
      }        
    } 
  }

  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { 
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        }
      });
    }
  }

  ngAfterViewInit(){
    if(document.getElementById('preloader')){
      document.getElementById('preloader')?.classList.add('hide');
    } 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.sidenav.close();
        this.settings.mainToolbarFixed = false;
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          } 
        }); 
      }            
    });    
  } 

  public getCategories(){  
    if(this.appService.Data.categories.length == 0) { 
      this.appService.getCategories().subscribe(data => { 
        this.appService.Data.categories = data;
      });
    } 
  }
  public getProducts(){ 
    if(this.appService.Data.ProductList.length == 0) {   
      this.appService.getProducts().subscribe((data:any) => { 
        this.appService.Data.ProductList = data.data.products;
      });
    } 
  }
  public onOtpChange(otp :any) {
    this.otp = otp;
  }
  public onLoginFormSubmit():void {
    
    if (this.loginForm.valid) {
      this.mobileNumber = this.loginForm.value.phone
      console.log(this.loginForm.value);
      const param = {
        mobile: this.loginForm.value.phone,
        cc: '+91',
        from:'2'
      };
      this.appService.checkMobileNumber(param).subscribe((data: any) => { 
        if (data && data.status == 200) {
          if(data.data.is_blocked == 2) {
            var status = 'error';
            this.snackBar.open('Your account has been deactivated. Please contact customer care', '×', {
              verticalPosition: 'top',
              duration: 3000,
              direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
              panelClass: [status] 
            });
          }else{
            this.generateOTP(param);
          }
          console.log('open modal');
          
        } else if (data && data.status == 500) {
            var status = 'error';
            this.snackBar.open(data.message, '×', {
              verticalPosition: 'top',
              duration: 3000,
              direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
              panelClass: [status] 
            });
        } else {
            var status = 'error';
            this.snackBar.open('Something went wrong', '×', {
              verticalPosition: 'top',
              duration: 3000,
              direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
              panelClass: [status] 
            });
        }
      });
      //this.router.navigate(['/']);
    }
  }
  public generateOTP(params : any){
    this.appService.getOTP(params).subscribe((data: any) => { 
      if (data && data.status == 200) {
        this.id = data.data.id
        this.showOtpComponent = true;
        
      }
    });
  }
  public verifyOTP(){
    const param = {
      id: this.id,
      otp: this.otp
    };
    this.appService.verifyOTP(param).subscribe((data: any) => { 
      if(data.status == 200 && data.data){
        const params = {
          id: data.data.id
        };
        this.appService.userById(params).subscribe((userData: any) => { 
          if (data && data.status === 200 && data.data && data.data.role_id == 2) {
            if (data && data.data && data.data.role_id == 2) {
              if (data.data.otp_verified == 1) {
                localStorage.setItem('uid', data.data.id);
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('contact', '+91'+data.data.number);
                this.appService.UserData.profile = data.data;
                localStorage.setItem('userData',JSON.stringify(this.appService.UserData));
                this.router.navigate(['/']);
              } else {
                console.log('not valid');
                var status = 'error';
                this.snackBar.open('Your are not verified.Please contact administrator', '×', {
                  verticalPosition: 'top',
                  duration: 3000,
                  direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
                  panelClass: [status] 
                });
              }
            } else {
              var status = 'error';
              this.snackBar.open('Not valid user', '×', {
                verticalPosition: 'top',
                duration: 3000,
                direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
                panelClass: [status] 
              });
            }
          } else if (data && data.status === 500) {
            var status = 'error';
            this.snackBar.open(data.data.message, '×', {
              verticalPosition: 'top',
              duration: 3000,
              direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
              panelClass: [status] 
            });
          } else {
            var status = 'error';
            this.snackBar.open('Something went wrong', '×', {
              verticalPosition: 'top',
              duration: 3000,
              direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
              panelClass: [status] 
            });
          }
        });
      }
    });
  }
}
