<mat-list  class="cart-items">
    <mat-list-item>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 -3">
            <form [formGroup]="communityForm" (ngSubmit)="oncommunityFormSubmit(communityForm.value)" class="custom-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
                <mat-form-field appearance="outline" floatLabel="always" class="subscribe-input" fxFlex>
                    <input matInput autocomplete="off" formControlName="community" (input)="searchCommunity($event)" placeholder="Your community...">                      
                </mat-form-field>
                <button mat-flat-button type="submit" class="subscribe-btn" color="primary">Search</button>
            </form>
        </div> 
    </mat-list-item>
    <mat-list-item>
        <div class="w-100 divider"></div> 
    </mat-list-item>
    <mat-list-item *ngFor="let menuItem of communityList">
        <h3 mat-line class="secondary-font fw-500 title"><a  (click)="selectCommunity(menuItem.id)">{{menuItem.name}}</a></h3>  
        <mat-icon>location_on</mat-icon>
    </mat-list-item>
</mat-list>
