<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">Choose your pincode</h2>
    </div>   
</div> 
<mat-dialog-content>  
    <div class="pt-3">
        <form [formGroup]="pincodeForm" (ngSubmit)="onPincodeFormSubmit(pincodeForm.value)" class="custom-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <mat-form-field appearance="outline" floatLabel="always" class="subscribe-input" fxFlex>
                <input matInput autocomplete="off" formControlName="pincode" placeholder="Your pincode...">                      
            </mat-form-field>
            <button mat-flat-button type="submit" class="subscribe-btn" color="primary">Update</button>
        </form>
    </div>  
</mat-dialog-content> 