<div mat-dialog-title> 
    <div class="text-center"> 
        <h4 class="mb-1">{{title}}</h4>
    </div> 
</div>
  
<div mat-dialog-content>
    <p class="text-center">{{message}}</p>
</div>
  
<div mat-dialog-actions fxLayoutAlign="space-between center" class="pb-4">
    <button mat-button (click)="onDismiss()">CANCEL</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">REPLACE</button>
</div>