import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { pincodeValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss']
})
export class PincodeComponent implements OnInit {
  public pincodeForm!: FormGroup;
  public selectedPincode: any;
  constructor(public router: Router,public appService:AppService,public formBuilder: FormBuilder,public dialogRef: MatDialogRef<PincodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(this.appService.getPincode()){
        this.selectedPincode =  this.appService.getPincode();
      }else{
        this.selectedPincode = '';
      }
     }
  
  ngOnInit(): void {
    this.pincodeForm = this.formBuilder.group({
      pincode: [this.selectedPincode, Validators.compose([Validators.required, pincodeValidator])]
  })
  }

  public onPincodeFormSubmit(values:any) {
    if (this.pincodeForm.valid) {
      this.appService.checkPincode(values.pincode).subscribe((store:any) => {
        if (store && store.status == 200 ) {
          localStorage.setItem('postal_code',values.pincode);
          this.dialogRef.close();
          this.router.navigate(['/menu']);
        }else{
          this.appService.openAlertDialog(store.data.message);
        }
      }, error => {
        this.appService.openAlertDialog('We are coming to your location soon!');
      });
    }
  }
}
