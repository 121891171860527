<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">Order #{{order.order_id}}</h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div> 
<mat-dialog-content>  
    <div class="pt-3"> 


        <div fxLayout="row wrap" class="flex-wrapper"> 
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Delivery Address</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <div class="divider"></div>
            </div>  
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Name:</p>
                <p>{{order.order_address.name}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Email:</p>
                <p>{{order.order_address.email}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Phone:</p>
                <p>{{order.order_address.mobile}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Country:</p>
                <p>India</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">City:</p>
                <p>{{order.order_address.city}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Landmark:</p>
                <p>{{order.order_address.landmark}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Zip/PostalCode:</p>
                <p>{{order.order_address.pincode}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Address:</p>
                <p>{{order.order_address.address}}</p>
            </div> 
            <div fxLayout="row" fxFlex="100" class="px-3 mb-2 mt-3">
                <div class="card-body" fxFlex="100">
                    <div fxLayout="row" fxFlex="100" class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                        <div *ngFor="let status of statuses"  [ngClass]="order.status >= status.id ? 'step completed' : 'step'"  >
                            <div class="step-icon-wrap">
                              <div class="step-icon"><mat-icon>{{status.icon}}</mat-icon></div>
                            </div>
                            <h4 class="step-title">{{status.name}}</h4>
                        </div>
                    </div>
                  </div>
            </div>

            <div fxFlex="100" class="px-3 mb-2 mt-3">
                <h2 class="secondary-font">Delivery Method</h2>
            </div>
            <div fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 
            <div fxFlex="100" class="px-3 mb-4">
                <p class="py-1">Standard Delivery <span class="text-muted"> {{order.delivery_date | date :'MMMM d, y'}} by {{order.delivery_time}}</span></p>
            </div> 

            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Payment Method</h2>
            </div>
            <div fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 
            <div fxFlex="100" class="px-3 mb-4">
                <p class="py-1"><span class="text-muted">{{order.payment_mode}}</span></p>
            </div> 
            
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Shopping Cart</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-5">
                <div class="divider"></div>
                <div class="mat-table theme-table review-order-table">
                    <div class="mat-header-row">
                        <div class="mat-header-cell image">Image</div>
                        <div class="mat-header-cell">Name</div>
                        <div class="mat-header-cell price">Unit Price</div>      
                        <div class="mat-header-cell quantity">Quantity</div>
                        <div class="mat-header-cell total">Total</div> 
                    </div> 
                    <div *ngFor="let item of order.details" class="mat-row">
                        <div class="mat-cell image"><img [src]="appService.mediaURL +''+item.product.primary_image" alt="item"></div>
                        <div class="mat-cell"><a [routerLink]="['/menu', item.product_id]" class="menu-item-name">{{item.product.name}}</a></div>
                        <div class="mat-cell price">  
                            <span  class="primary-color py-3">  
                                {{ item.price | currency : '₹' : 'symbol' : '1.2-2'}}
                            </span>  
                        </div>      
                        <div class="mat-cell quantity">{{item.quantity}}</div>
                        <div class="mat-cell total">
                            <span  class="primary-color py-3"> 
                                {{item.price*item.quantity | currency : '₹' : 'symbol' : '1.2-2'}}  
                            </span>  
                        </div> 
                    </div> 
                    <div class="mat-row">
                        <div class="mat-cell image"></div>
                        <div class="mat-cell"></div>
                        <div class="mat-cell price"></div>
                        <div class="mat-cell quantity text-right">
                        <div fxLayout="column" fxLayoutAlign="center end" class="grand-total px-2">
                            <span>SubTotal:</span>
                            <span class="text-muted">Delivery Charge:</span>
                            <span>Grand total:</span>
                        </div>        
                        </div>
                        <div class="mat-cell total">
                            <div fxLayout="column" fxLayoutAlign="center start" class="grand-total">
                                <span>{{ order.amount_to_be_paid | currency : '₹' : 'symbol' : '1.2-2'}}</span>
                                <span class="text-muted">{{order.delivery_charges | currency : '₹' : 'symbol' : '1.2-2'}}</span>
                                <span class="primary-color">{{ order.amount_to_be_paid | currency : '₹' : 'symbol' : '1.2-2'}}</span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div> 
            
        </div>   

    </div>  
</mat-dialog-content> 