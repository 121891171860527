import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  public times = [
    { icon: 'coffee-cup', title: 'Breakfast', hours:'8:00AM - 9:00AM' },
    { icon: 'lunch', title: 'Lunch', hours:'1:00PM - 2:00PM' },
    { icon: 'dinner-table', title: 'Dinner', hours:'8:00PM - 9:00PM' },
    { icon: 'ice-cream', title: 'Dessert', hours:'All time' }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
