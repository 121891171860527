import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser'
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Settings, AppSettings } from '../../app.settings';
import { pincodeValidator } from '../../theme/utils/app-validators';


@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss']
})
export class HeaderImageComponent implements OnInit {
  @Input('backgroundImage') backgroundImage:any;
  @Input('bgImageAnimate') bgImageAnimate:any;
  @Input('contentOffsetToTop') contentOffsetToTop:boolean = false;
  @Input('contentMinHeight') contentMinHeight:any;
  @Input('title') title:any;
  @Input('desc') desc:any;
  @Input('isHomePage') isHomePage:boolean = false;
  @Input('fullscreen') fullscreen: boolean = false; 
  public bgImage:any;
  public settings: Settings;
  public zipcodeForm!: FormGroup;
  constructor(public router: Router,public appService:AppService,public formBuilder: FormBuilder,public appSettings:AppSettings, private sanitizer:DomSanitizer) {
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.settings.headerBgImage = true;
      
    }); 
  }

  ngOnInit() {
    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      }); 
    } 
    if(this.backgroundImage){
      this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url('+this.backgroundImage +')'); 
    }
    this.zipcodeForm = this.formBuilder.group({
      community: ['', Validators.compose([Validators.required, pincodeValidator])]
    })
  }

  public onSubscribeFormSubmit(values:any):void {
    if (this.zipcodeForm.valid) {
        console.log(values);
        this.appService.checkPincode(values.community).subscribe((store:any) => {
          if (store && store.status == 200 ) {
            localStorage.setItem('postal_code',values.pincode);
            this.router.navigate(['/menu']);
          }else{
            this.appService.openAlertDialog(store.data.message);
          }
        }, error => {
          this.appService.openAlertDialog('We are coming to your location soon!');
        });
    }
}

  ngOnDestroy(){    
    setTimeout(() => {
      this.settings.headerBgImage = false; 
      this.settings.contentOffsetToTop = false;
    });  
  }

}
