import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
isLoggedIn: boolean | undefined;

  constructor(public appService:AppService, public router:Router) { }

  ngOnInit() {
    this.isLoggedIn = this.appService.getAuthStatus();
  }
  logout(){
    this.appService.Data.cartList = [];
    this.appService.Data.chefs = [];
    this.appService.Data.chefCartList = [];
    this.appService.Data.totalCartCount = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.UserData.orders = [];
    this.appService.UserData.profile = [];
    localStorage.setItem('cartData',JSON.stringify(this.appService.Data));
    localStorage.setItem('userData',JSON.stringify(this.appService.UserData));
    localStorage.removeItem('uid');
    this.router.navigate(['/login']);
  };
  
}
