import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CommunityComponent } from 'src/app/shared/community/community.component';
import { PincodeComponent } from 'src/app/shared/pincode/pincode.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  @Input() dividers:boolean = true;
  @Input() iconSize:string = 'sm';
  @Input() iconColor:string = '';
  constructor(public appService:AppService) { }

  ngOnInit() {
  }

  public openPincode(){
    const dialogRef = this.appService.openDialog(CommunityComponent, null, 'theme-dialog');
      dialogRef.afterClosed().subscribe(data => {  
        if(data){ 
          //this.showReservationMessage(data);
        } 
      }); 
  }
}
