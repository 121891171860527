<div class="header-carousel" [class.offset-bottom]="contentOffsetToTop" [class.fullscreen]="fullscreen" [ngClass]="'toolbar-'+settings.toolbar">
    <div *ngIf="slides.length > 1"  class="swiper-container h-100" [swiper]="config" (indexChange)="onIndexChange($event)">
        <div class="swiper-wrapper h-100">      
            <div *ngFor="let slide of slides;" class="swiper-slide">
                <div [attr.data-background]="slide.image" class="slide-item swiper-lazy">
                    <div class="swiper-lazy-preloader"></div>
                </div>
            </div>
        </div>     
        <button mat-raised-button color="primary" class="swiper-button-prev swipe-arrow"><mat-icon class="mat-icon-lg">keyboard_arrow_left</mat-icon></button>
        <button mat-raised-button color="primary" class="swiper-button-next swipe-arrow"><mat-icon class="mat-icon-lg">keyboard_arrow_right</mat-icon></button>
      
        <div fxLayout="column" fxLayoutAlign="center start" class="slide-info" [dir]="(settings.rtl) ? 'rtl' : 'ltr'">
            <mat-card class="banner-card">
                <h1 class="slide-title" [innerHTML] ="currentSlide.title"></h1>
                <!-- <h1 class="slide-title yellow" [innerHTML] ="currentSlide.subtitle"></h1> -->
                <p class="desc">{{currentSlide.desc}}</p> 
                <form  [formGroup]="zipcodeForm" (ngSubmit)="onSubscribeFormSubmit(zipcodeForm.value)" class="pincode-form custom-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="100" ngClass.sm="mt-2" ngClass.xs="mt-2">
                    <mat-form-field appearance="outline" floatLabel="always" class="subscribe-input" fxFlex>
                        <input matInput autocomplete="off" (input)="searchCommunity($event)" formControlName="community" placeholder="Search Community">                      
                    </mat-form-field>
                    <button mat-flat-button type="submit" class="submit-btn" color="primary">Find Community</button>
                </form>  
            </mat-card>
        </div>
        <mat-card class="result-card" *ngIf="zipcodeForm.value.community != '' && communityList.length > 0">
            <mat-list  class="cart-items">
                <mat-list-item *ngFor="let menuItem of communityList">
                    <h3 mat-line class="secondary-font fw-500 title"><a  (click)="selectCommunity(menuItem.id)">{{menuItem.name}}</a></h3>  
                    <mat-icon>location_on</mat-icon>
                </mat-list-item>
            </mat-list>   
        </mat-card>
        <mat-card class="result-card" *ngIf="zipcodeForm.value.community != '' && communityList.length == 0">
            <h4>Hold Tight! We’re coming to your Community soon!. Our team is working tirelessly to bring the best quality products</h4>  
        </mat-card>
        <div class="mask"></div>
    </div>
</div>