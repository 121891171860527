<div class="section">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">How Mom's Meal Works</h1> 
            <!-- <p class="section-desc text-muted">This is what we do and we do it perfectly.</p>  -->

            <div fxLayout="row wrap" class="services-wrapper">
                <div fxFlex="100" fxFlex.gt-md="33.3" fxFlex.gt-sm="33.3" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <img src="assets/images/others/days.png" >
                        <h2 class="secondary-font capitalize fw-600 my-3">Choose your delivery days</h2>
                        <p>Pick the dates on which you would like to have mom's meal</p>           
                    </mat-card> 
                </div>
                <div fxFlex="100" fxFlex.gt-md="33.3" fxFlex.gt-sm="33.3" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <img src="assets/images/others/meals.png" >
                        <h2 class="secondary-font capitalize fw-600 my-3">Browse & build your meals</h2>
                        <p>Select Cuisines, cook and dishes to try our Homemade meal</p>             
                    </mat-card> 
                </div>

                <div fxFlex="100" fxFlex.gt-md="33.3" fxFlex.gt-sm="33.3" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <img src="assets/images/others/shef.png" >
                        <h2 class="secondary-font capitalize fw-600 my-3">Delivered, Eat, Repeat</h2>
                        <p>Mom's meal will be delivered at your doorstep to enjoy the homemade food</p>             
                    </mat-card> 
                </div>
            </div>
                
        </div>
    </div>   
</div>