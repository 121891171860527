import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service'; 
import { CartOverviewComponent } from 'src/app/shared/cart-overview/cart-overview.component'; 
import { CommunityComponent } from 'src/app/shared/community/community.component';
import { PincodeComponent } from 'src/app/shared/pincode/pincode.component';
import { ReservationDialogComponent } from 'src/app/shared/reservation-dialog/reservation-dialog.component';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html' 
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>(); 
  constructor(public router: Router,public appService:AppService,private changeDetector : ChangeDetectorRef) { }

  ngOnInit() { }
  ngAfterViewChecked(){
     this.changeDetector.detectChanges(); 
  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }

  public getPincode(){
    if(this.appService.getPincode()){
      return this.appService.getPincode();
    }else{
      return 'Select Pincode';
    }
  }
  public getCommunityName(){
    if(this.appService.Data.community.name){
      return this.appService.getPincode();
    }else{
      return 'Select Pincode';
    }
  }
  public openCart(){ 
    //this.appService.openCart(CartOverviewComponent)
    this.router.navigate(['/cart']);
  }
  public openPincode(){ 
    this.appService.openComponentModel(CommunityComponent, null, true)
  }
  public reservation(){ 
    this.appService.makeReservation(ReservationDialogComponent, null, true);   
  }
}