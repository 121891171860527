import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {
  public communityForm!: FormGroup;
  public selectedCommunityId: any;
  communityList: any = [];
  constructor(public router: Router,public appService:AppService,public formBuilder: FormBuilder,public dialogRef: MatDialogRef<CommunityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(this.appService.getCommunityId()){
        this.selectedCommunityId =  this.appService.Data.community.name;
      }else{
        this.selectedCommunityId = '';
      }
     }
  
  ngOnInit(): void {
    this.communityForm = this.formBuilder.group({
      community: [this.selectedCommunityId, Validators.compose([Validators.required])]
  })
  }

  public oncommunityFormSubmit(values:any) {
    if (this.communityForm.valid) {
      this.appService.searchCommunity(values.community).subscribe((community:any) => {
        if (community && community.status == 200 ) {
          this.communityList = community.data;
        }else{
          this.appService.openAlertDialog('We are coming to your location soon!');
        }
      }, error => {
        this.communityList = [];
        this.appService.openAlertDialog(error.error.message);
      });
    }
  }
  public searchCommunity(community:any) {
    console.log(community);
    if (community.target.value != '') {
      this.appService.searchCommunity(community.target.value).subscribe((community:any) => {
        if (community && community.status == 200 ) {
          this.communityList = community.data;
          //localStorage.setItem('community',community.data.id);
          //this.dialogRef.close();
          //this.router.navigate(['/menu']);
        }else{
          this.communityList = [];
          this.appService.openAlertDialog(community.message);
        }
      }, error => {
        this.communityList = [];
        this.appService.openAlertDialog(error.error.message);
      });
    }
  }
  public selectCommunity(community:any) {
    localStorage.setItem('community',community);
    this.dialogRef.close();
    this.router.navigate(['/menu']);
  }
}
