import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input('menuItemId') menuItemId = 0;
  public commentForm!: FormGroup;
  public reviews : any;
  public ratings = [
    { title: 'Very Dissatisfied', icon: 'sentiment_very_dissatisfied', percentage: 20, selected: false },
    { title: 'Dissatisfied', icon: 'sentiment_dissatisfied', percentage: 40, selected: false },
    { title: 'Neutral', icon: 'sentiment_neutral', percentage: 60, selected: false },
    { title: 'Satisfied', icon: 'sentiment_satisfied', percentage: 80, selected: false },
    { title: 'Very Satisfied', icon: 'sentiment_very_satisfied', percentage: 100, selected: false }
  ];
  constructor(public appService:AppService,public fb: FormBuilder) { }

  ngOnInit() {
    this.getReviews(this.menuItemId);
    this.commentForm = this.fb.group({ 
      review: [null, Validators.required],            
      name: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      rate: null,
      id: this.menuItemId
    }); 
  }

  public onCommentFormSubmit(values:any){
    if (this.commentForm.valid) { 
      console.log(values);
      if(values.rate){
        values.pid = this.menuItemId;
        values.way = 'order';
        values.uid = localStorage.getItem('uid');
        values.msg = values.review;
        //menuItem.ratingsCount++,
        //menuItem.ratingsValue = menuItem.ratingsValue + values.rate,
        this.appService.saveRating(values).subscribe((data:any) => { 
          console.log(data);
        });
      }     
    } 
  }

  public rate(rating:any){
    this.ratings.filter(r => r.selected = false);
    this.ratings.filter(r => r.percentage == rating.percentage)[0].selected = true;
    this.commentForm.controls.rate.setValue(rating.percentage);
  }
  public getReviews(id:any){
    this.appService.getProductRatings({id:id}).subscribe((data:any) => { 
      this.reviews = data.data.rating;
    });
  }
}