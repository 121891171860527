import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.scss']
})
export class CategoryMenuComponent implements OnInit {

  public awards:any[] = [];
  public config: SwiperConfigInterface = { };
  categories: any;
  constructor(public appService:AppService) { }

  
  ngOnInit(): void {
    this.getCategories();
  }
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 8,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      pagination: true,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      autoplay: false,
      speed: 500,
      effect: "slide",
      breakpoints: {
        280: {
          slidesPerView: 3
        },
        480: {
          slidesPerView: 5
        },
        600: {
          slidesPerView: 6
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 3
        },
        1500: {
          slidesPerView: 3
        }
      }
    }
  }
  public getCategories(){
    this.appService.getCategories().subscribe((categories:any)=>{
      this.categories = categories.data.categories;
      this.appService.Data.categories = categories.data.categories;
    })
  }
}
