import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { Settings, AppSettings } from '../../app.settings';
import { pincodeValidator } from '../../theme/utils/app-validators';

@Component({
  selector: 'app-header-carousel',
  templateUrl: './header-carousel.component.html',
  styleUrls: ['./header-carousel.component.scss'] 
})
export class HeaderCarouselComponent implements OnInit {
  @Input('slides') slides: Array<any> = [];
  @Input('contentOffsetToTop') contentOffsetToTop:boolean = false; 
  @Input('fullscreen') fullscreen: boolean = true; 
  public config: SwiperConfigInterface = {};  
  public currentSlide:any; 
  public settings: Settings;
  public zipcodeForm!: FormGroup;
  selectedPincode: string | boolean | null;
  communityList: any=[];
  constructor(public router: Router,public formBuilder: FormBuilder,public appSettings:AppSettings,public appService:AppService) { 
    this.settings = this.appSettings.settings;
    if(this.appService.getPincode()){
      this.selectedPincode =  this.appService.getPincode();
    }else{
      this.selectedPincode = '';
    }
  }

  ngOnInit() {
    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    }
    this.zipcodeForm = this.formBuilder.group({
      community: [this.selectedPincode, Validators.compose([Validators.required])]
    }) 
  }

  ngAfterViewInit(){    
    this.initCarousel();
  }

  ngOnChanges(){
    if(this.slides.length > 0){
      this.currentSlide = this.slides[0];  
    }
  }

  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: false,        
      loop: false,
      preloadImages: false,
      lazy: true,     
      autoplay: false,
      speed: 500,
      effect: "slide"
    }
  }

  ngOnDestroy(){  
    setTimeout(() => {
      this.settings.contentOffsetToTop = false;
    });  
  }
  public onSubscribeFormSubmit(values:any):void {
    if (this.zipcodeForm.valid) {
        console.log(values);
        this.appService.checkCommunity(values.community).subscribe((community:any) => {
          if (community && community.status == 200 ) {
            localStorage.setItem('community',community.data.id);
            this.router.navigate(['/menu']);
          }else{
            this.appService.openAlertDialog('We are coming to your location soon!');
          }
        }, error => {
          this.appService.openAlertDialog('We are coming to your location soon!');
        });
    }
  }
  public searchCommunity(community:any) {
    console.log(community);
    if (community.target.value != '') {
      this.appService.searchCommunity(community.target.value).subscribe((community:any) => {
        if (community && community.status == 200 ) {
          this.communityList = community.data;
          //localStorage.setItem('community',community.data.id);
          //this.dialogRef.close();
          //this.router.navigate(['/menu']);
        }else{
          this.communityList = []
        }
      }, error => {
        this.communityList = []
      });
    }
  }
  public selectCommunity(community:any) {
    localStorage.setItem('community',community);
    this.router.navigate(['/menu']);
  }
  public onIndexChange(index: number) {  
    this.currentSlide = this.slides[index];
  }
}