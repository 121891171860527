<div class="">
    <div class="px-3">
        <div class="theme-container">
            <div class="awards-carousel"> 
                <div class="swiper-container h-100" [swiper]="config"> 
                    <div class="swiper-wrapper h-100">      
                        <div *ngFor="let cat of categories" class="swiper-slide">
                            <div class="cusine-filter">
                                <div class="cusine-filter-img">
                                    <img src="https://beta.topchop.in/storage/chef/icons/icon2.png" alt="arrow">
                                </div>
                                <div class="cusine-filter-title">
                                    <div class="cusine-filter-title-text">
                                        {{cat.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
                 
        </div>
    </div>   
  </div>