import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, Order, Category } from 'src/app/app.models'; 
import { AppSettings } from 'src/app/app.settings'; 
import { environment } from 'src/environments/environment';   
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { map } from 'rxjs/operators';
import * as moment from 'moment';


export class Data {
  constructor(public categories: Category[],
              public community: any,
              public chefs: any[], 
              public ProductList: any[], 
              public cartList: any[],
              public chefCartList: any[],
              public orderList: Order[],
              public favorites: MenuItem[], 
              public totalPrice: number,
              public deliveryPrice: number,
              public totalCartCount: number,
              public grandTotalPrice: number,
              ) {
                
               }
}
export class UserData {
  constructor(public orders: any[],
              public profile: any
              ) {
                
               }
}
@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [],  // categories
    [], //community
    [],  //chefs 
    [],  // products 
    [],  // cartList
    [], // chefCartList
    [],  // orderList
    [],  // favorites 
    0, // totalPrice
    0, // deliveryPrice
    0 ,//totalCartCount,
    0 //GrandTotal
  );
  public UserData = new UserData(
    [],  // orders
    [],  //profile information
  )  
  
  public url = environment.url + '/assets/data/';
  public baseUrl = environment.baseURL;
  public adminUrl = environment.adminURL;
  public mediaURL = environment.mediaURL; 

  constructor(public http:HttpClient, 
              private datePipe:DatePipe,
              public datepipe: DatePipe,
              private bottomSheet: MatBottomSheet, 
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              public appSettings:AppSettings,
              public translateService: TranslateService) {
                  let cartData = localStorage.getItem('cartData');
                  if (cartData && cartData != null && cartData !== 'null') {
                    this.Data = JSON.parse(cartData);
                  }else{
                    this.Data =new Data([], [], [], [] , [], [],  [],  [], 0,  0,  0 ,0);
                  }

                  let userData = localStorage.getItem('userData');
                  if (userData && userData != null && userData !== 'null') {
                    this.UserData = JSON.parse(userData);
                  }else{
                    this.UserData =new UserData([], []);
                  }
               }  
  public getAuthStatus(){
    const uid = localStorage.getItem('uid');
    if (uid && uid != null && uid !== 'null') {
        return true;
    }
    return false;
  }
  public profile(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.authToken
      })
    };
    return this.http.post(environment.baseURL + 'users/getById', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              console.log('response is');
              console.log(response);
              response.data.avatar = response.data.avatar!=''?environment.mediaURL+response.data.avatar:'';
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public updateProfile(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.authToken
      })
    };
    return this.http.post(environment.baseURL + 'users/update_profile', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
			        response.data.avatar = response.data.avatar!=''?response.data.avatar:'';
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public addAddress(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.authToken
      })
    };
    return this.http.post(environment.baseURL + 'address/save', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public editAddress(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.authToken
      })
    };
    return this.http.post(environment.baseURL + 'address/edit', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getMenuItems(): Observable<any[]>{
    const input = {
      postal_code: localStorage.getItem('postal_code')
    };
    return this.http.post(this.baseUrl + 'products/all', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getChefItemsCount(input : any){
    return this.http.post(this.baseUrl + 'shef_foods', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getChefItems(input : any){
    return this.http.post(this.baseUrl + 'shef_foods', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getChefItems2(input : any){
    return this.http.post(this.baseUrl + 'shef_foods', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public chefProduct(input : any){
    return this.http.post(this.baseUrl + 'chefProduct_by_id', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getPincode(){
    if(localStorage.getItem('postal_code')){
      return localStorage.getItem('postal_code');
    }
    return false;
  }
  public getCommunityId(){
    if(localStorage.getItem('community')){
      return localStorage.getItem('community');
    }
    return false;
  }
  public getMenuItemById(id:number): Observable<any>{
    const param = {
      id: id,
      postal_code: localStorage.getItem('postal_code')
    }
    return this.http.post(this.baseUrl + 'products/getById', param)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
 
  public getSpecialMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(this.url + 'special-menu-items.json');
  } 

  public getBestMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(this.url + 'best-menu-items.json');
  } 
  public getTrendingItemsByPincode(): Observable<any[]>{
    const input = {
      postal_code: 500018
    };
    return this.http.post(this.adminUrl + 'home/trending', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }

  public getCategories(): Observable<any[]>{
    return this.http.get<any[]>(this.baseUrl + 'food_types');
  }
  public getCusines(): Observable<any[]>{
    return this.http.get<any[]>(this.baseUrl + 'cuisines');
  }
  public getCommunity(): Observable<any[]>{
    return this.http.get<any[]>(this.baseUrl + 'community/'+localStorage.getItem('community'));
  }
  public getCommunities(): Observable<any[]>{
    return this.http.get<any[]>(this.baseUrl + 'communities');
  }
  public checkMobileNumber(input : any){
    return this.http.post(environment.baseURL + 'users/checkMobileNumber', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getOTP(input : any){
    return this.http.post(environment.baseURL + 'users/send_otp', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public verifyOTP(input : any){
    return this.http.post(environment.baseURL + 'users/verifyOTP', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public userById(input : any){
    return this.http.post(environment.baseURL + 'users/getById', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public placeOrder(input : any){
    return this.http.post(environment.baseURL + 'chef_orders/save_slots', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public saveRazorpay(input : any){
    return this.http.post(environment.baseURL + 'chef_orders/save_razorpay', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getRazorCustId(input : any){
    return this.http.post(environment.baseURL + 'orders/saveOrGetCustomerInRazorpay', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getUserAddresses(community_id : any){
    const input = {
      id: localStorage.getItem('uid'),
      community_id: community_id
    };
    return this.http.post(environment.baseURL + 'address/getByUserid', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public addUserAddresses(input : any){
    return this.http.post(environment.baseURL + 'address/update', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public registerChef(input:any){
    const httpOptions = {
      headers: new HttpHeaders({
        "basic": environment.authToken
      })
    };
    return this.http.post(environment.adminURL + 'chef/register', input,httpOptions)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public saveRating(input : any): Observable<any[]>{
    return this.http.post(this.baseUrl + 'rating/save', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getProductRatings(input : any): Observable<any[]>{
    return this.http.post(this.baseUrl + 'rating/getFromIDs', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  } 
  public getProducts(): Observable<any[]>{
    const input = {
      postal_code: localStorage.getItem('postal_code')
    };
    return this.http.post(this.baseUrl + 'products/all', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              response.products.forEach((info:any) => {
                info.name = info.name.toLowerCase();
                info.is_free=0;
                info.offer_price= info.mrp;
                if(info.is_delivery_notice ==0){
                  var today_date = new Date();
                  var tomorrow_date = new Date();
                  tomorrow_date = new Date(tomorrow_date.setDate(tomorrow_date.getDate() +1));
                  if(info.isAvailable.length > 0){
                    let today_available_batches = info.isAvailable.filter((x:any) => new Date(x.inwarded_at.replace(/-/g, "/")) <= today_date && new Date(x.expiry_at.replace(/-/g, "/")) >= today_date && x.count > 0);
                    let tomorrow_available_batches = info.isAvailable.filter((x:any) => new Date(x.inwarded_at.replace(/-/g, "/")) <= tomorrow_date && new Date(x.expiry_at.replace(/-/g, "/")) >= tomorrow_date && x.count > 0);
                    if(today_available_batches.length>0){
                      info.available_from = today_date;
                      info.count = 0; 
                      today_available_batches.forEach((x:any) => {
                        info.count = info.count + x.count;
                      });
                    }else if(tomorrow_available_batches.length>0){
                      info.available_from = tomorrow_date;
                      info.count = 0; 
                      tomorrow_available_batches.forEach((x:any) => {
                        info.count = info.count + x.count;
                      });
                    }else{
                      info.available_from = new Date(today_date.setDate(today_date.getDate() +2));
                      info.count = 1000;
                    }
                    info.availability_text  = this.calcDays(new Date(), info.available_from);
                  }else{
                    var currentDate = new Date();
                    var numberOfDaysToAdd = 2;
                    var result = currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
                    info.available_from = new Date(result);
                    
                    info.count = 1000;
                    info.availability_text = this.calcDays(new Date(), info.available_from);
                  }
                }else{
                  var currentDate = new Date();
                  let numberOfDaysToAdd = info.delivery_hours;
                  var result = currentDate.setHours(currentDate.getHours()+numberOfDaysToAdd);
                  info.available_from = new Date(result);
                  
                  info.count = 1000;
                  info.availability_text = 'Available in '+info.delivery_hours+'hour(s)';
                }
              });
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  } 
  public calcDays(date1:any,date2:any){

    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return]
    if (Math.round(differenceMs / ONE_DAY)==0) return '120 mins';
    if (Math.round(differenceMs / ONE_DAY) ==1) return 'Tomorrow 7-9am';
    if (Math.round(differenceMs / ONE_DAY) >1) return 'After 2 days';
    else return 'After  2 days';
  }
  public checkPincode(community:any){
    const input = {
      community: community
    };
    return this.http.post<any[]>(environment.baseURL + 'chef/check_community', input);
  }
  public checkCommunity(community:any){
    const input = {
      community: community
    };
    return this.http.post<any[]>(environment.baseURL + 'chef/check_community', input);
  }
  public searchCommunity(community:any){
    const input = {
      community: community
    };
    return this.http.post<any[]>(environment.baseURL + 'chef/search_community', input);
  }
  public getHomeCarouselSlides(){
    return this.http.get<any[]>(this.url + 'slides.json');
  }

  public getReservations(){
    return this.http.get<any[]>(this.url + 'reservations.json');
  }

  public getOrders(){
    const input = {
      id: localStorage.getItem('uid')
    };
    return this.http.post(this.baseUrl + 'orders/mealsByUid', input)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }

  public getGUID(){
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    }).toLowerCase();
    return guid;
  }

  public addToCart(menuItem:any, component:any){  
    if(!this.Data.cartList.find(item=>item.id == menuItem.id)){
      //localStorage.setItem('previousDate',menuItem.delivery_date);
      menuItem.quantity = (menuItem.quantity) ? menuItem.quantity : 1;
      this.Data.cartList.push(menuItem); 
      this.calculateCartTotal(); 
      if(component){
        this.openCart(component);        
      }
      else{ 
        this.snackBar.open( menuItem.name + ' has been added to cart.', '×', {
          verticalPosition: 'top',
          duration: 3000,
          direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
          panelClass: ['success'] 
        });
      }
    } 
  }
  public groupBy( array : any , f: any )
  {
    var groups :any = {};
    array.forEach( function( o: any )
    {
      var group = JSON.stringify( f(o) );
      groups[group] = groups[group] || [];
      groups[group].push( o );  
    });
    return Object.keys(groups).map( function( group )
    {
      return groups[group]; 
    })
  }
  public openComponentModel(dialogComponent:any, data:any, onDialog:boolean = false){
    if(onDialog){
      const dialogRef = this.openDialog(dialogComponent, null, 'theme-dialog');
      dialogRef.afterClosed().subscribe(data => {  
        if(data){ 
          this.showReservationMessage(data);
        } 
      }); 
    }
    else{
      this.showReservationMessage(data);
    } 
  }
  public openCart(component:any){  
    this.bottomSheet.open(component, {
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
    }).afterDismissed().subscribe(isRedirect=>{  
      if(isRedirect){ 
        window.scrollTo(0,0);  
      }        
    });  
  }

  public calculateCartTotal(){
    this.Data.totalPrice = 0;
    this.Data.totalCartCount = 0;
    this.Data.deliveryPrice = 0;
    this.Data.grandTotalPrice = 0;
    this.Data.cartList.forEach(item=>{
      let price = 0;
      if(item.discount){
        price = item.offer_price - (item.offer_price * (item.discount / 100));
      }
      else{
        price = item.offer_price;
      }
      this.Data.totalPrice = this.Data.totalPrice + (price * item.quantity); 
      this.Data.totalCartCount = this.Data.totalCartCount + item.quantity;   
    });
    if(this.Data.totalPrice <= 249){
      this.Data.deliveryPrice = 20;
    }
    this.Data.grandTotalPrice = this.Data.totalPrice + this.Data.deliveryPrice;
    localStorage.setItem('cartData',JSON.stringify(this.Data));
    
  }
  public calculateCartTotalbyChef(id :any){
    console.log(this.Data.cartList);
    this.Data.totalPrice = 0;
    this.Data.totalCartCount = 0;
    let data = this.Data.cartList.filter((item:any) => item.chef_id == id);
    data.forEach(item=>{
      let price = 0;
      if(item.discount){
        price = item.offer_price - (item.offer_price * (item.discount / 100));
      }
      else{
        price = item.offer_price;
      }

      this.Data.totalPrice = this.Data.totalPrice + (price * item.quantity); 
      this.Data.totalCartCount = this.Data.totalCartCount + item.quantity; 
       
    });
    localStorage.setItem('cartData',JSON.stringify(this.Data));
    
  }
  public checkCartList(menuItem :any){
    this.Data.totalPrice = 0;
    this.Data.totalCartCount = 0;
    let data = this.Data.cartList.filter((item:any) => item.chef_id == menuItem.chef_id);
    data.forEach(item=>{
      let chef_filter = this.Data.cartList.find((item : any) => item.id == menuItem.id);
      if(!chef_filter){
        this.Data.cartList.filter(x => x.id !== menuItem.id);
      }
      this.calculateCartTotalbyChef(menuItem.chef_id);       
    });
  }
  public checkCartItemTimings(){
    this.Data.cartList.forEach(item=>{
      console.log(item);
      let rrr =  this.categoryTimeCheck(item.chef_id,item.food_type_id,item.delivery_date);
      if(rrr){
        const index: number = this.Data.cartList.indexOf(item);
        console.log(this.Data.cartList);
        if (index !== -1) {
          item.quantity = 0;
          item.cartCount = 0;
          this.Data.cartList.splice(index, 1);
          localStorage.setItem('cartData',JSON.stringify(this.Data)); 
        }
      }       
    });
  }
  public categoryTimeCheck(chef_id : any,category_id : any,date : any){
    var chef = this.Data.chefs.find((chef : any) => chef.id == chef_id);
    var selectedCategory = chef.timings.find((item : any) => item.category_id == category_id);
    var selectedDate = this.datepipe.transform(new Date(date), 'yyyy-MM-dd');
    var todayDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    if(selectedCategory){
      if(selectedDate == todayDate){

        var start_time = selectedCategory.start_time.split(":");
        let start = new Date();
        start.setHours(start_time[0]);
        start.setMinutes(start_time[1]);
        start.setSeconds(start_time[2]);
  
        var end_time = selectedCategory.end_time.split(":");
        let end = new Date();
        end.setHours(end_time[0]);
        end.setMinutes(end_time[1]);
        end.setSeconds(end_time[2]);
        console.log(category_id);
        if ((new Date(date).getTime() >= new Date(start).getTime()) && (new Date(date).getTime() <= new Date(end).getTime())) {
          console.log('false');
          return false;
        } else {
          console.log('true');
          return true;
        }    
      }else{
        console.log('false');
        return false;
      }
    }else{
      return true;
    }
  }
  updateChefCart(id: number,name: string,date:any){
    // console.log(id);
    // let chef_data;
    // if(this.Data.chefCartList.length >0){
    //   chef_data = this.Data.chefCartList.find((item : any) => item.chef_id == id);
    // }
    // //let chef_data = this.Data.chefCartList.find((item : any) => item.chef_id == id);
    // console.log(chef_data);
    // if(chef_data){
    //   let index = this.Data.chefCartList.findIndex((item : any) => item.chef_id == id);
    //   this.Data.chefCartList[index].time = null
    //   this.Data.chefCartList[index].date = date;
    //   this.Data.chefCartList[index].products = this.Data.cartList.filter((item:any) => item.chef_id == id);
    // }else if(chef_data === undefined){
    //   this.Data.chefCartList.push({
    //     chef_id: id,
    //     name: name,
    //     date: date,
    //     time: null,
    //     products : this.Data.cartList.filter((item:any) => item.chef_id == id)
    //   })
    // }
    // this.Data.chefCartList.forEach((chef, index)=>{
    //   chef.totalPrice = 0;
    //   chef.totalCartCount = 0;
    //   chef.products.forEach((item:any, i :any)=>{
    //     let price = 0;
    //     if(item.discount){
    //       price = item.offer_price - (item.offer_price * (item.discount / 100));
    //     }
    //     else{
    //       price = item.offer_price;
    //     }
  
    //     chef.totalPrice = chef.totalPrice + (price * item.quantity); 
    //     chef.totalCartCount = chef.totalCartCount + item.quantity; 
         
    //   });
    // });
    var slots = this.Data.cartList;
    const merged = slots.reduce((r, { deliver_date, chef_id, food_type_id,food_type_name,...rest }) => {
      const key = `${deliver_date}-${chef_id}-${food_type_id}-${food_type_name}`;
      r[key] = r[key] || { deliver_date, chef_id, food_type_id,food_type_name, products: [] };
      r[key]["products"].push(rest)
      return r;
    }, {});
    
    this.Data.chefCartList= Object.values(merged);
    //console.log(timeTable);
    // this.Data.chefCartList = this.Data.chefCartList.filter(function (el) {
    //   return el.products.length > 0;
    // });
    localStorage.setItem('cartData',JSON.stringify(this.Data));
  }
  public verifyDates(){
    this.Data.chefCartList.forEach((chef, index)=>{
      if(new Date(chef.date).toLocaleDateString() <= new Date().toLocaleDateString()){
        this.Data.chefCartList.splice(index, 1);
      }
    });
    localStorage.setItem('cartData',JSON.stringify(this.Data));
  }
  public calculateCartGrandTotal(){
    this.Data.totalPrice = 0;
    this.Data.totalCartCount = 0;
    this.Data.chefCartList.forEach(item=>{
      this.Data.totalPrice = this.Data.totalPrice + item.totalPrice; 
      this.Data.totalCartCount = this.Data.totalCartCount + item.totalCartCount;    
    });
    localStorage.setItem('cartData',JSON.stringify(this.Data));
    
  }
  public addToFavorites(menuItem:MenuItem){
    let message:string, status:string;
    if(this.Data.favorites.find(item=>item.id == menuItem.id)){ 
      message = 'The menu item "' + menuItem.name + '" already added to favorites.'; 
      status = 'error';    
    } 
    else{
      this.Data.favorites.push(menuItem);
      message = 'The menu item "' + menuItem.name + '" has been added to favorites.'; 
      status = 'success';  
    } 
    this.snackBar.open(message, '×', {
      verticalPosition: 'top',
      duration: 3000,
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
      panelClass: [status] 
    });   
  }

  public openDialog(component:any, data:any, panelClass:any){ 
    return this.dialog.open(component, {
      data: data, 
      panelClass: panelClass,
      autoFocus: false,
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
    });  
  }

  public openConfirmDialog(title:string, message:string) {  
    const dialogData = new ConfirmDialogModel(title, message); 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    }); 
    return dialogRef; 
  }
  public openCartResetDialog(title:string, message:string) {  
    const dialogData = new ConfirmDialogModel(title, message); 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    }); 
    return dialogRef; 
  }
  public openAlertDialog(message:string) {   
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: message
    }); 
    return dialogRef; 
  }

  public makeReservation(dialogComponent:any, data:any, onDialog:boolean = false){
    if(onDialog){
      const dialogRef = this.openDialog(dialogComponent, null, 'theme-dialog');
      dialogRef.afterClosed().subscribe(data => {  
        if(data){ 
          this.showReservationMessage(data);
        } 
      }); 
    }
    else{
      this.showReservationMessage(data);
    } 
  }
  private showReservationMessage(data:any){
    this.snackBar.open('Dear ' + data.fullName + ', thank you for your reservation! Your reservation at Popino on the '+ this.datePipe.transform( data.date,'dd-MM-yyyy') +' at '+ data.time +' for '+ data.guests +' people will review by our team and someone will be in touch soon.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 9000 });
  } 

  public getTranslateValue(key:string, param:string = ''){  
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    }) 
    return value; 
  } 

  public filterData(data:any, categoryId:number, sort?:string, page?:number, perPage?:number){  
    console.log(categoryId);
    if(categoryId){
      data = data.filter((item:any) => item.food_type_id == categoryId);
      console.log(data);
    }   
    //alert(categoryId);
    //for show more properties mock data 
    // for (var index = 0; index < 2; index++) {
    //   data = data.concat(data);        
    // }     
     
    this.sortData(sort, data);
    return this.paginator(data, page, perPage)
  }
  public filterChefs(data:any, deliveryTypeId:number, sort?:string, page?:number, perPage?:number){  
    console.log(data);
    data = data.filter((item:any) => item.transport_type == deliveryTypeId || item.transport_type == 3);
    console.log(data);  
    return data;
  }
  public sortData(sort?:string, data?:any){
    if(sort){
      switch (sort) { 
        case 'Popular':
          data = data.sort((a:any, b:any) => { 
            if(a.ratingsValue/a.ratingsCount < b.ratingsValue/b.ratingsCount){
              return 1;
            }
            if(a.ratingsValue/a.ratingsCount > b.ratingsValue/b.ratingsCount){
              return -1;
            }
            return 0; 
          });
          break;
        case 'Price (Low to High)': 
          data = data.sort((a:any,b:any) => {
            if(a.price > b.price){
              return 1;
            }
            if(a.price < b.price){
              return -1;
            }
            return 0;  
          });
          break;
        case 'Price (High to Low)': 
          data = data.sort((a:any,b:any) => {
            if(a.price < b.price){
              return 1;
            }
            if(a.price > b.price){
              return -1;
            }
            return 0;  
          }); 
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items:any, page?:any, perPage?:any) { 
    var page = page || 1,
    perPage = perPage || 4,
    offset = (page - 1) * perPage,   
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }  

  public getCountries(){
    return [ 
        {name: 'Afghanistan', code: 'AF'}, 
        {name: 'Aland Islands', code: 'AX'}, 
        {name: 'Albania', code: 'AL'}, 
        {name: 'Algeria', code: 'DZ'}, 
        {name: 'American Samoa', code: 'AS'}, 
        {name: 'AndorrA', code: 'AD'}, 
        {name: 'Angola', code: 'AO'}, 
        {name: 'Anguilla', code: 'AI'}, 
        {name: 'Antarctica', code: 'AQ'}, 
        {name: 'Antigua and Barbuda', code: 'AG'}, 
        {name: 'Argentina', code: 'AR'}, 
        {name: 'Armenia', code: 'AM'}, 
        {name: 'Aruba', code: 'AW'}, 
        {name: 'Australia', code: 'AU'}, 
        {name: 'Austria', code: 'AT'}, 
        {name: 'Azerbaijan', code: 'AZ'}, 
        {name: 'Bahamas', code: 'BS'}, 
        {name: 'Bahrain', code: 'BH'}, 
        {name: 'Bangladesh', code: 'BD'}, 
        {name: 'Barbados', code: 'BB'}, 
        {name: 'Belarus', code: 'BY'}, 
        {name: 'Belgium', code: 'BE'}, 
        {name: 'Belize', code: 'BZ'}, 
        {name: 'Benin', code: 'BJ'}, 
        {name: 'Bermuda', code: 'BM'}, 
        {name: 'Bhutan', code: 'BT'}, 
        {name: 'Bolivia', code: 'BO'}, 
        {name: 'Bosnia and Herzegovina', code: 'BA'}, 
        {name: 'Botswana', code: 'BW'}, 
        {name: 'Bouvet Island', code: 'BV'}, 
        {name: 'Brazil', code: 'BR'}, 
        {name: 'British Indian Ocean Territory', code: 'IO'}, 
        {name: 'Brunei Darussalam', code: 'BN'}, 
        {name: 'Bulgaria', code: 'BG'}, 
        {name: 'Burkina Faso', code: 'BF'}, 
        {name: 'Burundi', code: 'BI'}, 
        {name: 'Cambodia', code: 'KH'}, 
        {name: 'Cameroon', code: 'CM'}, 
        {name: 'Canada', code: 'CA'}, 
        {name: 'Cape Verde', code: 'CV'}, 
        {name: 'Cayman Islands', code: 'KY'}, 
        {name: 'Central African Republic', code: 'CF'}, 
        {name: 'Chad', code: 'TD'}, 
        {name: 'Chile', code: 'CL'}, 
        {name: 'China', code: 'CN'}, 
        {name: 'Christmas Island', code: 'CX'}, 
        {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {name: 'Colombia', code: 'CO'}, 
        {name: 'Comoros', code: 'KM'}, 
        {name: 'Congo', code: 'CG'}, 
        {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {name: 'Cook Islands', code: 'CK'}, 
        {name: 'Costa Rica', code: 'CR'}, 
        {name: 'Cote D\'Ivoire', code: 'CI'}, 
        {name: 'Croatia', code: 'HR'}, 
        {name: 'Cuba', code: 'CU'}, 
        {name: 'Cyprus', code: 'CY'}, 
        {name: 'Czech Republic', code: 'CZ'}, 
        {name: 'Denmark', code: 'DK'}, 
        {name: 'Djibouti', code: 'DJ'}, 
        {name: 'Dominica', code: 'DM'}, 
        {name: 'Dominican Republic', code: 'DO'}, 
        {name: 'Ecuador', code: 'EC'}, 
        {name: 'Egypt', code: 'EG'}, 
        {name: 'El Salvador', code: 'SV'}, 
        {name: 'Equatorial Guinea', code: 'GQ'}, 
        {name: 'Eritrea', code: 'ER'}, 
        {name: 'Estonia', code: 'EE'}, 
        {name: 'Ethiopia', code: 'ET'}, 
        {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {name: 'Faroe Islands', code: 'FO'}, 
        {name: 'Fiji', code: 'FJ'}, 
        {name: 'Finland', code: 'FI'}, 
        {name: 'France', code: 'FR'}, 
        {name: 'French Guiana', code: 'GF'}, 
        {name: 'French Polynesia', code: 'PF'}, 
        {name: 'French Southern Territories', code: 'TF'}, 
        {name: 'Gabon', code: 'GA'}, 
        {name: 'Gambia', code: 'GM'}, 
        {name: 'Georgia', code: 'GE'}, 
        {name: 'Germany', code: 'DE'}, 
        {name: 'Ghana', code: 'GH'}, 
        {name: 'Gibraltar', code: 'GI'}, 
        {name: 'Greece', code: 'GR'}, 
        {name: 'Greenland', code: 'GL'}, 
        {name: 'Grenada', code: 'GD'}, 
        {name: 'Guadeloupe', code: 'GP'}, 
        {name: 'Guam', code: 'GU'}, 
        {name: 'Guatemala', code: 'GT'}, 
        {name: 'Guernsey', code: 'GG'}, 
        {name: 'Guinea', code: 'GN'}, 
        {name: 'Guinea-Bissau', code: 'GW'}, 
        {name: 'Guyana', code: 'GY'}, 
        {name: 'Haiti', code: 'HT'}, 
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {name: 'Holy See (Vatican City State)', code: 'VA'}, 
        {name: 'Honduras', code: 'HN'}, 
        {name: 'Hong Kong', code: 'HK'}, 
        {name: 'Hungary', code: 'HU'}, 
        {name: 'Iceland', code: 'IS'}, 
        {name: 'India', code: 'IN'}, 
        {name: 'Indonesia', code: 'ID'}, 
        {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {name: 'Iraq', code: 'IQ'}, 
        {name: 'Ireland', code: 'IE'}, 
        {name: 'Isle of Man', code: 'IM'}, 
        {name: 'Israel', code: 'IL'}, 
        {name: 'Italy', code: 'IT'}, 
        {name: 'Jamaica', code: 'JM'}, 
        {name: 'Japan', code: 'JP'}, 
        {name: 'Jersey', code: 'JE'}, 
        {name: 'Jordan', code: 'JO'}, 
        {name: 'Kazakhstan', code: 'KZ'}, 
        {name: 'Kenya', code: 'KE'}, 
        {name: 'Kiribati', code: 'KI'}, 
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {name: 'Korea, Republic of', code: 'KR'}, 
        {name: 'Kuwait', code: 'KW'}, 
        {name: 'Kyrgyzstan', code: 'KG'}, 
        {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {name: 'Latvia', code: 'LV'}, 
        {name: 'Lebanon', code: 'LB'}, 
        {name: 'Lesotho', code: 'LS'}, 
        {name: 'Liberia', code: 'LR'}, 
        {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {name: 'Liechtenstein', code: 'LI'}, 
        {name: 'Lithuania', code: 'LT'}, 
        {name: 'Luxembourg', code: 'LU'}, 
        {name: 'Macao', code: 'MO'}, 
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {name: 'Madagascar', code: 'MG'}, 
        {name: 'Malawi', code: 'MW'}, 
        {name: 'Malaysia', code: 'MY'}, 
        {name: 'Maldives', code: 'MV'}, 
        {name: 'Mali', code: 'ML'}, 
        {name: 'Malta', code: 'MT'}, 
        {name: 'Marshall Islands', code: 'MH'}, 
        {name: 'Martinique', code: 'MQ'}, 
        {name: 'Mauritania', code: 'MR'}, 
        {name: 'Mauritius', code: 'MU'}, 
        {name: 'Mayotte', code: 'YT'}, 
        {name: 'Mexico', code: 'MX'}, 
        {name: 'Micronesia, Federated States of', code: 'FM'}, 
        {name: 'Moldova, Republic of', code: 'MD'}, 
        {name: 'Monaco', code: 'MC'}, 
        {name: 'Mongolia', code: 'MN'}, 
        {name: 'Montserrat', code: 'MS'}, 
        {name: 'Morocco', code: 'MA'}, 
        {name: 'Mozambique', code: 'MZ'}, 
        {name: 'Myanmar', code: 'MM'}, 
        {name: 'Namibia', code: 'NA'}, 
        {name: 'Nauru', code: 'NR'}, 
        {name: 'Nepal', code: 'NP'}, 
        {name: 'Netherlands', code: 'NL'}, 
        {name: 'Netherlands Antilles', code: 'AN'}, 
        {name: 'New Caledonia', code: 'NC'}, 
        {name: 'New Zealand', code: 'NZ'}, 
        {name: 'Nicaragua', code: 'NI'}, 
        {name: 'Niger', code: 'NE'}, 
        {name: 'Nigeria', code: 'NG'}, 
        {name: 'Niue', code: 'NU'}, 
        {name: 'Norfolk Island', code: 'NF'}, 
        {name: 'Northern Mariana Islands', code: 'MP'}, 
        {name: 'Norway', code: 'NO'}, 
        {name: 'Oman', code: 'OM'}, 
        {name: 'Pakistan', code: 'PK'}, 
        {name: 'Palau', code: 'PW'}, 
        {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {name: 'Panama', code: 'PA'}, 
        {name: 'Papua New Guinea', code: 'PG'}, 
        {name: 'Paraguay', code: 'PY'}, 
        {name: 'Peru', code: 'PE'}, 
        {name: 'Philippines', code: 'PH'}, 
        {name: 'Pitcairn', code: 'PN'}, 
        {name: 'Poland', code: 'PL'}, 
        {name: 'Portugal', code: 'PT'}, 
        {name: 'Puerto Rico', code: 'PR'}, 
        {name: 'Qatar', code: 'QA'}, 
        {name: 'Reunion', code: 'RE'}, 
        {name: 'Romania', code: 'RO'}, 
        {name: 'Russian Federation', code: 'RU'}, 
        {name: 'RWANDA', code: 'RW'}, 
        {name: 'Saint Helena', code: 'SH'}, 
        {name: 'Saint Kitts and Nevis', code: 'KN'}, 
        {name: 'Saint Lucia', code: 'LC'}, 
        {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {name: 'Samoa', code: 'WS'}, 
        {name: 'San Marino', code: 'SM'}, 
        {name: 'Sao Tome and Principe', code: 'ST'}, 
        {name: 'Saudi Arabia', code: 'SA'}, 
        {name: 'Senegal', code: 'SN'}, 
        {name: 'Serbia and Montenegro', code: 'CS'}, 
        {name: 'Seychelles', code: 'SC'}, 
        {name: 'Sierra Leone', code: 'SL'}, 
        {name: 'Singapore', code: 'SG'}, 
        {name: 'Slovakia', code: 'SK'}, 
        {name: 'Slovenia', code: 'SI'}, 
        {name: 'Solomon Islands', code: 'SB'}, 
        {name: 'Somalia', code: 'SO'}, 
        {name: 'South Africa', code: 'ZA'}, 
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {name: 'Spain', code: 'ES'}, 
        {name: 'Sri Lanka', code: 'LK'}, 
        {name: 'Sudan', code: 'SD'}, 
        {name: 'Suriname', code: 'SR'}, 
        {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {name: 'Swaziland', code: 'SZ'}, 
        {name: 'Sweden', code: 'SE'}, 
        {name: 'Switzerland', code: 'CH'}, 
        {name: 'Syrian Arab Republic', code: 'SY'}, 
        {name: 'Taiwan, Province of China', code: 'TW'}, 
        {name: 'Tajikistan', code: 'TJ'}, 
        {name: 'Tanzania, United Republic of', code: 'TZ'}, 
        {name: 'Thailand', code: 'TH'}, 
        {name: 'Timor-Leste', code: 'TL'}, 
        {name: 'Togo', code: 'TG'}, 
        {name: 'Tokelau', code: 'TK'}, 
        {name: 'Tonga', code: 'TO'}, 
        {name: 'Trinidad and Tobago', code: 'TT'}, 
        {name: 'Tunisia', code: 'TN'}, 
        {name: 'Turkey', code: 'TR'}, 
        {name: 'Turkmenistan', code: 'TM'}, 
        {name: 'Turks and Caicos Islands', code: 'TC'}, 
        {name: 'Tuvalu', code: 'TV'}, 
        {name: 'Uganda', code: 'UG'}, 
        {name: 'Ukraine', code: 'UA'}, 
        {name: 'United Arab Emirates', code: 'AE'}, 
        {name: 'United Kingdom', code: 'GB'}, 
        {name: 'United States', code: 'US'}, 
        {name: 'United States Minor Outlying Islands', code: 'UM'}, 
        {name: 'Uruguay', code: 'UY'}, 
        {name: 'Uzbekistan', code: 'UZ'}, 
        {name: 'Vanuatu', code: 'VU'}, 
        {name: 'Venezuela', code: 'VE'}, 
        {name: 'Viet Nam', code: 'VN'}, 
        {name: 'Virgin Islands, British', code: 'VG'}, 
        {name: 'Virgin Islands, U.S.', code: 'VI'}, 
        {name: 'Wallis and Futuna', code: 'WF'}, 
        {name: 'Western Sahara', code: 'EH'}, 
        {name: 'Yemen', code: 'YE'}, 
        {name: 'Zambia', code: 'ZM'}, 
        {name: 'Zimbabwe', code: 'ZW'} 
    ]
  } 

  public getTestimonials(){
    return [
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mr. Adam Sandler', 
            position: 'General Director', 
            image: 'assets/images/profile/adam.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Ashley Ahlberg', 
            position: 'Housewife', 
            image: 'assets/images/profile/ashley.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Bruno Vespa', 
            position: 'Blogger', 
            image: 'assets/images/profile/bruno.jpg' 
        },
        { 
            text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
            author: 'Mrs. Julia Aniston', 
            position: 'Marketing Manager', 
            image: 'assets/images/profile/julia.jpg' 
        }
    ];
  }

  public getChefs(){
    return this.http.get<any[]>(this.baseUrl + 'chefs');
  }
  public getChefsByPincode(){
    return this.http.get<any[]>(this.baseUrl + 'chefs/'+localStorage.getItem('postal_code'));
  }
  public getChefsByCommunity(){
    return this.http.get<any[]>(this.baseUrl + 'chefs/'+localStorage.getItem('community'));
  }
  public getCuisines(){
    return this.http.get<any[]>(this.baseUrl + 'cuisines');
  } 
  public getChefById(id : any){
    const param = {
      id: id,
      limit:  10,
      postal_code: localStorage.getItem('postal_code')
    };
    return this.http.post(this.baseUrl + 'products/getByChefId', param)
      .pipe(
        map(
          (response:any) => {
            if (response) {
              console.log(response);
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }
  public getAwards(){
    return [  
        { name: 'award-1', image: 'assets/images/awards/1.png' },
        { name: 'award-2', image: 'assets/images/awards/2.png' },
        { name: 'award-3', image: 'assets/images/awards/3.png' },
        { name: 'award-4', image: 'assets/images/awards/4.png' },
        { name: 'award-5', image: 'assets/images/awards/5.png' },
        { name: 'award-6', image: 'assets/images/awards/6.png' },
        { name: 'award-7', image: 'assets/images/awards/7.png' } 
    ];
  }

  public getDeliveryMethods(){
    return [
        { value: '1', name: 'Standard Delivery', desc: '(8AM - 9AM)' },
        { value: '2', name: 'Pickup', desc: '(12PM - 1PM)' }
    ]
  }

  public getPaymentMethods(){
    return [
        // { value: 'cod', name: 'Cash on delivery', desc: '' },
        { value: 'paynow', name: 'Online Payment', desc: '' }
    ]
  }
  public getMonths(){
    return [
      { value: '01', name: 'January' },
      { value: '02', name: 'February' },
      { value: '03', name: 'March' },
      { value: '04', name: 'April' },
      { value: '05', name: 'May' },
      { value: '06', name: 'June' },
      { value: '07', name: 'July' },
      { value: '08', name: 'August' },
      { value: '09', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' }
    ]
  }

  public getYears(){
    const startYear = new Date().getFullYear();
    let years = Array();  
    for (let i = 0; i <= 10; i++) { 
      years.push(startYear + i);      
    }   
    return years; 
  } 

  public shuffleArray(array:any){
    var currentIndex = array.length, temporaryValue, randomIndex; 
    while (0 !== currentIndex) { 
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1; 
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    } 
    return array;
  } 

  public convertImgToBase64(url:string, callback:any){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  
  private mailApi = 'https://mailthis.to/codeninja'
  public PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' })
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  } 

}
